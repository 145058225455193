import { useContext, useEffect } from "react";
import RouteComponent from "./components/routers/routerAnimate";
import { restore } from "./page_utile";
import { ScrollbarContext } from "./store";
import { gsap } from "gsap";
import { useNavigate } from "react-router";
import {
  chiaBackward,
  problemiBackward,
  toHome,
} from "./page_utile/pageTransition";

function App() {
  const nav = useNavigate();
  const { position, scroll, navPage, navPageSecretM } =
    useContext(ScrollbarContext);

  useEffect(() => {
    function disableHorizontal() {
      if (window.innerHeight < 500) {
        if (window.screen?.orientation && window.screen?.orientation.lock) {
          window.screen?.orientation.lock("portrait").catch((error) => {
            console.error("Failed to lock the orientation:", error);
          });
        }
      }
    }
    window.addEventListener("resize", disableHorizontal);
    disableHorizontal();
    const popstateEvent = () => {
      if (navPage) {
        toHome();
        navPageSecretM(false);
      }
      if (window.location.href === window.location.origin + "/") {
        if (position < 700 && position > 100) {
          chiaBackward();
          problemiBackward();
          scroll?.scrollTo(0, position);
        } else if (position > 700 && position < 2000) {
          problemiBackward();
          scroll?.scrollTo(0, position);
        } else {
          gsap.to(".servizi", { xPercent: 0, duration: 2, overwrite: true });
          gsap.to(".benefici", { display: "flex" });
          gsap.to(".menu", { yPercent: 0, ease: "Expo.easeInOut" });
          scroll?.scrollTo(0, position);
          restore();
        }
      }
    };
    window.addEventListener("popstate", popstateEvent);
    return () => {
      window.removeEventListener("resize", disableHorizontal);
    };
  }, [position, nav]);
  return <RouteComponent />;
}

export default App;
