import cost from "../svgs/COSTELLAZIONE ALTAIR.svg";
import gas_blue from "../svgs/GRADIENT SFONDO 4.svg";
import galaxy from "../svgs/GALASSIA SFONDO.png";

import { useContext, useEffect, useState } from "react";
import { ScrollbarContext } from "../store";
import { GET } from "../https";
import ReactMarkdown from "react-markdown";
import right_arrow from "../images/right-arrow.svg";
import { Link, useNavigate } from "react-router-dom";

import { chiaForward } from "../page_utile/pageTransition";

const Chia = () => {
  const nav = useNavigate();
  const { scroll, language, positionSetter } = useContext(ScrollbarContext);

  const [fetchData, setfetchData] = useState({});
  const [fetchData2, setfetchData2] = useState({});
  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/home-page?locale=${language}&populate=*`);
        setfetchData(result);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    const fetchHome2 = async () => {
      try {
        const result = await GET(`/pages?locale=${language}&populate=*`);

        setfetchData2(result.data);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome2();
    fetchHome();
  }, [language]);

  function urlHander(id, http) {
    if (fetchData) {
      const seoTitle = http
        ? http[id]?.attributes.SEO.seoTitle
        : fetchData[id]?.attributes.SEO.seoTitle;

      return "/" + seoTitle;
    }
  }
  function ChiaTranstion() {
    nav(urlHander(0, fetchData2));

    chiaForward();

    positionSetter(scroll.scrollTop);
  }
  return (
    <section className="chia">
      <div className="chia__together">
        <img className="chia__gas-blue" src={gas_blue} alt="gas-effect" />
        <div className="chia__text-wrapper">
          <h2 className="chia__text-wrapper--title">
            {fetchData?.data?.attributes?.title_ChiaSiamo}
          </h2>
          <p className="chia__text-wrapper--text">
            <ReactMarkdown>
              {fetchData?.data?.attributes?.desc_chiaSiamo}
            </ReactMarkdown>
          </p>
          <div onClick={ChiaTranstion} className="clickable">
            <Link className="problemi__wrapper--arrow-text">
              {language === "en" ? "Find out more" : "Scopri di più"}
            </Link>
            <img
              className="problemi__wrapper--right-arrow"
              src={right_arrow}
              alt="right arrow"
            />
          </div>
        </div>
        <img src={cost} alt="cost" className="chia__cost" />
      </div>
      {/* <img className="chia__star" src={chia_star} alt="chia_star" /> */}
      <img src={galaxy} alt="chia_galaxy" className="chia__galaxy" />
    </section>
  );
};

export default Chia;
