import "../style/style.css";
import Home from "../components/home";
import Header from "../components/header";
import Chia from "../components/chi";
import Smooth from "../components/smooth-scroll";
import "../font/AvenirBlack/AvenirBlack.ttf";
import Menu from "../components/menu";
import ScrollT from "../components/scrollTrigger";
import Problemi from "../components/problemi";
import Subproblemi from "../components/sub-problemi";
import Service from "../components/service";
import { Outlet } from "react-router";
import ServiceModules from "../components/services";
import Benefici from "../components/benefici";
import Rece from "../components/rece";
import Contact from "../components/contact";
import Packages from "../components/pakage";
import { useContext, useEffect, useState } from "react";
import { ScrollbarContext } from "../store";
import Spinner from "./loader";
import { Offline } from "react-detect-offline";

const Main = () => {
  const [extraLoader, setExtraLoader] = useState(false);

  const { doneFetch } = useContext(ScrollbarContext);
  useEffect(() => {
    setTimeout(() => {
      setExtraLoader(true);
    }, 100);
  }, [extraLoader]);

  return (
    <div id="smooth">
      <Smooth />
      <ScrollT />
      <div className="pin"></div>
      <div className={extraLoader && doneFetch ? "App" : "white-screen"}>
        {<Header />}
        <Outlet />
        <Home />

        <Chia />
        <Problemi />
        <Subproblemi />
        <Service />
        <ServiceModules />
        <Benefici />
        <Rece />
        <Contact />
        <Packages />

        {<Menu />}
      </div>
    </div>
  );
};

export default Main;
