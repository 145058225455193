import { gsap, ScrollTrigger } from "gsap/all";
import blu_gas from "../svgs/GRADIENT SFONDO 4New.svg";
import galaxy from "../svgs/GALASSIA SFONDO.png";
import blue from "../svgs/Path 676-min.svg";
import star_2 from "../svgs/../svgs/Stelle.svg";
import { useContext, useEffect, useState } from "react";
import { ScrollbarContext } from "../store";
import { GET } from "../https";
import ReactMarkdown from "react-markdown";

gsap.registerPlugin(ScrollTrigger);
const Service = () => {
  const [fetchData, setfetchData] = useState({});
  const { language } = useContext(ScrollbarContext);
  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/home-page?locale=${language}&populate=*`);

        setfetchData(result);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
  }, [language]);
  return (
    <section className="service">
      <img src={star_2} alt="star" className="service__star2" />
      <img src={star_2} alt="star" className="service__star3" />
      <img src={blu_gas} alt="blue gas" className="service__blue-gas" />
      <div className="service__text-wrapper">
        <h2 className="service__text-wrapper--title">
          {fetchData?.data?.attributes.title_soluzioni}
        </h2>
        <p className="service__text-wrapper--text">
          <ReactMarkdown>
            {fetchData?.data?.attributes.desc_soluzioni}
          </ReactMarkdown>
        </p>
      </div>
      <img src={galaxy} alt="galaxy" className="service__galaxy" />
    </section>
  );
};

export default Service;
