import { gsap } from "gsap";
import { restore } from ".";
let isCompletedChia = false;

export const chiaForward = () => {
  isCompletedChia = false;
  gsap.to(".header__white-elipse--left", {
    display: "none",
    duration: 2,
  });
  gsap.to(".header__white-elipse", {
    display: "none",
    duration: 2,
  });
  gsap.to(
    ".chia__text-wrapper",
    {
      x: -6000,
      duration: 0,
    },
    "<100%"
  );
  gsap.to(".chia__together", {
    xPercent: -100,
    duration: 2,
  });
  gsap.to(".chia__galaxy", { yPercent: 100, duration: 2 });
  gsap.to(
    ".chia__galaxy",
    {
      xPercent: -400,
      duration: 2,
      onComplete: () => {
        isCompletedChia = true;
      },
    },
    "<100%"
  );

  gsap.set(".problemi__blackhole", { opacity: 0 });
  gsap.to(".a", { opacity: 0, duration: 0 });
  gsap.to(".problemi__stuck-to-blackhole", { x: 6000 });
  gsap.to(".problemi__together", {
    x: -window.innerWidth - 100,
    duration: 0,
  });
  // check.eventCallback("onComplete", () => {
  //   isCompletedChia = true;
  // });
};

export const chiaBackward = () => {
  if (isCompletedChia) {
    gsap.fromTo(".chia__galaxy", { opacity: 0 }, { opacity: 1, duration: 8 });
    gsap.to(".chia__galaxy", {
      yPercent: -40,
      xPercent: 0,
      duration: 2,
    });
    gsap.to(".service-detail", { opacity: 0, duration: 2 });
    gsap.to(".chia__text-wrapper", { x: 0, overwrite: true });
    gsap.to(".chia__together", { xPercent: 0, duration: 2 }, "<10%");
    gsap.to(".problemi__stuck-to-blackhole", { x: 0 });
    gsap.to(".a", { opacity: 1, duration: 2 });
    gsap.to(".problemi__together", { x: 0, duration: 2 });
    gsap.to(".subproblemi", { x: 0, duration: 2 });
    gsap.to(".menu", { yPercent: 0, ease: "Expo.easeInOut" });
    gsap.to(".problemi__blackhole", { opacity: 1 });
    gsap.to(".problemi__together", { x: 0, duration: 2 });
  } else {
    gsap.set(".chia__galaxy", { yPercent: -40, xPercent: 0, overwrite: true });
    gsap.set(".service-detail", { opacity: 0, overwrite: true });
    gsap.set(".chia__text-wrapper", { x: 0, overwrite: true });
    gsap.set(".chia__together", { xPercent: 0, overwrite: true });
    gsap.set(".menu", { yPercent: 0, ease: "Expo.easeInOut", overwrite: true });
    gsap.set(".problemi__blackhole", { opacity: 1, scale: 1 });
    gsap.to(".a", { opacity: 1, duration: 0 });
    gsap.to(".problemi__stuck-to-blackhole", { x: 0 });
    gsap.to(".problemi__together", {
      x: 0,
      duration: 0,
    });
  }
};

export const problemiForward = () => {
  gsap.to(".problemi__together", {
    x: -window.innerWidth - 100,
    duration: 2,
  });
  gsap.to(".a", { opacity: 0, duration: 2 });
  gsap.to(".header__white-elipse--left", { x: -4000, duration: 2 });
  gsap.to(".header__white-elipse", { y: 4000, duration: 2 });
  gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
  gsap.to(".problemi__stuck-to-blackhole", { x: 6000 });
  gsap.to(".problemi__blackhole", { opacity: 0, duration: 2 });
};

export const problemiBackward = () => {
  gsap.to(".service-detail", { opacity: 0, duration: 2 });
  gsap.to(".a", { opacity: 1, duration: 2 });
  gsap.to(".problemi__together", { x: 0, duration: 2 });
  gsap.to(".subproblemi", { x: 0, duration: 2 });
  gsap.to(".menu", { yPercent: 0, ease: "Expo.easeInOut" });
  gsap.to(".header__white-elipse--left", { x: 0, duration: 2 });
  gsap.to(".header__white-elipse", { y: -40, duration: 2 });
  gsap.to(".subproblemi", { display: "flex" });
  gsap.to(".problemi__blackhole", { opacity: 1, overwrite: true });
  gsap.to(".problemi__stuck-to-blackhole", { x: 0 });
};

export const toEcomerce = (scroll, positionSetter) => {
  if (scroll.scrollTop > 3000) {
  } else {
    gsap.to(".problemi__star--1", { visibility: "hidden", duration: 0 });
    gsap.to(".problemi__star--2", { visibility: "hidden", duration: 0 });
    gsap.to(".problemi__galaxy", { visibility: "hidden", duration: 0 });
    gsap.to(".header__astroy", { visibility: "hidden" });
    gsap.to(".a", { opacity: 0 });
    gsap.to(".problemi__together", { visibility: "hidden", duration: 0 });
    gsap.to(".home", { opacity: 0, duration: 1 });
    gsap.to(".service-detail", { opacity: 1, duration: 1 });
    gsap.to(".chia", { opacity: 0, duration: 0 });
    gsap.to(".header__white-elipse", { visibility: "hidden" });
    gsap.to(".header__white-elipse--left", { visibility: "hidden" });
    gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    gsap.to(".problemi__stuck-to-blackhole", { x: 6000 });
  }
  // setTimeout(() => nav("/ecomerce"), 0);
};
export const toHome = () => {
  gsap.to(".problemi__together", { visibility: "visible", duration: 0 });
  gsap.to(".servizi", { xPercent: 0, overwrite: true }, "<");
  gsap.to(".menu", { yPercent: 0, ease: "Expo.easeInOut" }, "<");
  gsap.to(".chia", { opacity: 1, duration: 1 }, "<");
  gsap.to(".home", { opacity: 1, duration: 1 }, "<");
  gsap.to(".a", { opacity: 1 }, "<");
  gsap.to(".service-detail", { opacity: 0, duration: 1 }, "<");
  gsap.to(".problemi__together", { x: 0, opacity: 1 }, "<");
  gsap.to(".header__white-elipse", { opacity: 0.1 }, "<");
  gsap.to(".header__white-elipse--left", { opacity: 0.2 }, "<");
  gsap.to(".header__astroy", { visibility: "visible" }, "<");
  gsap.to(".problemi__star--1", { visibility: "visible", duration: 0 }, "<");
  gsap.to(".problemi__star--2", { visibility: "visible", duration: 0 }, "<");
  gsap.to(".problemi__galaxy", { visibility: "visible", duration: 0 }, "<");
  gsap.to(".problemi__stuck-to-blackhole", { x: 0, overwrite: true }, "<");

  //
  gsap.set(".chia__galaxy", { yPercent: -40, xPercent: 0 });
  gsap.set(".chia__text-wrapper", { x: 0, overwrite: true });
  gsap.set(".chia__together", { xPercent: 0, overwrite: true });
  gsap.to(".a", { opacity: 1, duration: 2 });
  gsap.to(".subproblemi", { x: 0, duration: 2 });
  gsap.set(".problemi__blackhole", { opacity: 1, scale: 1 });

  restore();
};
