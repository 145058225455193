import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import tlHome from "./time-line";
gsap.registerPlugin(ScrollTrigger);
const phone = window.matchMedia("(max-width:500px)");
function homeOnScroll(phone) {
  const width = document.getElementById("width");
  gsap.to(".menu__track", { width: "12px" });

  gsap.to(".header__white-elipse", { opacity: 0.05 });
  gsap.to(".home__star", { rotate: "0deg" });
  // gsap.to(".home__star2", { rotate: "90deg" });
  gsap.to(".servizi", { display: "none" });

  const yoyo = gsap.to(".home__arrow-down", { y: 10, yoyo: true, repeat: 3 });

  const homeStrigger = {
    trigger: "#smooth",
    start: 0,
    end: "+=400",
    scrub: 0.4,
    snap: true,
    onUpdate: (data) => {
      yoyo.kill();
    },
  };

  ScrollTrigger.matchMedia({
    "(min-width:800px)": function () {
      gsap.fromTo(
        ".home__background",
        { opacity: 0.8 },
        {
          opacity: 0.2,
          scrollTrigger: homeStrigger,
        }
      );
    },
    "(max-width:799px)": function () {
      gsap.to(".menu", { x: 10 });

      tlHome
        .fromTo(
          ".home__blackhole",
          { scale: 3 },
          {
            yPercent: 100,

            scrollTrigger: homeStrigger,
          }
        )

        .fromTo(
          ".home__arrow-down",
          { y: 0 },
          {
            scale: 1.3,
            y: -1000,
            scrollTrigger: homeStrigger,
          }
        );
      tlHome
        .fromTo(
          ".home__star",
          { scale: 0.2, yPercent: -14 },
          { scale: 2, opacity: 0, scrollTrigger: homeStrigger }
        )

        .fromTo(".menu", { x: 10 }, { x: -150, scrollTrigger: homeStrigger })
        .fromTo(
          ".home__background",
          { opacity: 0.6 },
          {
            opacity: 0.2,
            scrollTrigger: homeStrigger,
          }
        );
    },
    // },
    all: function () {
      tlHome
        .to(".home__title", {
          y: -1000,
          scale: 1.3,
          scrollTrigger: homeStrigger,
        })
        .to(".home__semi-title", {
          y: -1000,
          scale: 1.3,
          scrollTrigger: homeStrigger,
        })
        .fromTo(
          ".home__star2",
          {
            scale: 3,
          },
          { scale: 4, scrollTrigger: homeStrigger }
        )

        .to(".home__arrow-down", {
          scale: 1.3,
          y: -1000,
          scrollTrigger: homeStrigger,
        })
        .fromTo(
          ".home__blackhole",
          { scale: 1.2 },
          {
            yPercent: 100,

            scrollTrigger: homeStrigger,
          }
        )
        .fromTo(
          ".home__star",
          { yPercent: 0, scale: 4, opacity: 0.8 },
          {
            yPercent: -300,
            xPercent: 15,
            scale: 11,
            opacity: 0,
            scrollTrigger: homeStrigger,
          }
        )
        // .fromTo(
        //   ".home__star2",
        //   { yPercent: -60, scale: 1.4, opacity: 1 },
        //   {
        //     yPercent: -70,
        //     scale: 1.7,
        //     opacity: 0.4,
        //     scrollTrigger: homeStrigger,
        //   }
        // )
        .to(".header__astroy", {
          x: -630,
          y: 200,
          opacity: 0.4,
          scrollTrigger: homeStrigger,
        })
        .to(".home__dark-area", {
          opacity: 0,
          scrollTrigger: homeStrigger,
        })

        .fromTo(
          ".home__gas-effect1",
          { opacity: 0.6 },
          { opacity: 0, scrollTrigger: homeStrigger }
        )
        .to(".home__blue", { opacity: 0, scrollTrigger: homeStrigger })
        .to(".home__elipse", { opacity: 0, scrollTrigger: homeStrigger })
        .fromTo(
          ".header__white-elipse",
          { opacity: 0.05 },
          {
            opacity: 0,
            scrollTrigger: homeStrigger,
          }
        )
        .fromTo(
          ".header__white-elipse--left",
          { opacity: 0.2 },
          {
            opacity: 0,
            scrollTrigger: homeStrigger,
          }
        );

      tlHome.fromTo(
        ".menu__track",
        { width: 13 },
        {
          width: 122,
          scrollTrigger: homeStrigger,
        }
      );
    },
  });
}

export default homeOnScroll;
