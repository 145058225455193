import { gsap } from "gsap";

let timmer;
export let tlcost;
export function rotateCost() {
  if (timmer) {
    clearTimeout(timmer);
  }
  timmer = setTimeout(() => {
    // tlcost = gsap.timeline({
    //   defaults: { duration: 3, ease: "Expo.easeInOut" },
    // });
    // tlcost.to(".chia__cost", {
    //   rotate: "-12deg",
    // });
    // tlcost.to(".home__star2", { x: -200 }, "<");
    // tlcost.to(".chia__galaxy", { x: 200, y: 0 }, "<");
  }, 1000);
}
