import blackhole from "../svgs/PIANETA.svg";
import arrowDown from "../images/arrow-down.svg";
import gasEffect1 from "../svgs/GRADIENT SFONDO.svg";
import darkArea from "../svgs/GRADIENT SFONDO 2.svg";
import { gsap, ScrollTrigger } from "gsap/all";
import white_elipse from "../svgs/Ellipse 1.svg";
import blue from "../svgs/Path 676-min.svg";
import home_background from "../svgs/BACKGROUND BLU.svg";
import { chai } from "../gsapAinmation/scrollTo";
import { useContext, useEffect, useState } from "react";
import { ScrollbarContext } from "../store";
import home_star from "../svgs/Stelle.svg";
import { GET } from "../https";
import Meta from "./meta";
import axios from "axios";

gsap.registerPlugin(ScrollTrigger);
const Home = () => {
  const { language, fetchDone, doneFetch } = useContext(ScrollbarContext);
  const [fetchData, setfetchData] = useState({});
  const { home_title, TEXT, SEO } = fetchData;

  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/home-page?locale=${language}&populate=*`);
        fetchDone(true);
        setfetchData(result?.data.attributes);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
  }, [language]);

  const { scroll } = useContext(ScrollbarContext);

  return (
    <section className={"home"}>
      <Meta
        title={SEO?.seoTitle}
        description={SEO?.seoDescription}
        keywords={SEO?.seoKeywords}
      />

      <img className="home__star" src={home_star} alt="home star" />
      <img className="home__star2" src={home_star} alt="home star" />
      <img
        src={home_background}
        alt="home background"
        className="home__background"
      />
      <img src={blue} alt="blue" className="home__blue blue--1" />
      <img src={blue} alt="blue" className="home__blue blue--2" />
      <img src={blue} alt="blue" className="home__blue blue--3" />
      <img src={blue} alt="blue" className="home__blue blue--4" />
      <img src={blue} alt="blue" className="home__blue blue--5" />
      <img src={white_elipse} alt="white_elipse" className="home__elipse" />
      <img className="home__gas-effect1" src={gasEffect1} alt="gas-effect" />

      <img src={darkArea} alt="dark-area" className="home__dark-area" />

      <h1 className="home__title">
        {home_title !== "undefined" ? home_title : ""}
      </h1>
      <p className="home__semi-title">{TEXT !== "undefined" ? TEXT : ""}</p>
      <img
        onClick={chai.bind(this, scroll)}
        src={arrowDown}
        alt="arrow-down"
        className="home__arrow-down"
      />

      <img className="home__blackhole" src={blackhole} alt="pianeta" />
    </section>
  );
};

export default Home;
