import { useEffect, useState } from "react";
import { createContext } from "react";
import { GET } from "../https";

export const ScrollbarContext = createContext({
  setScrollTo: () => {},
  scroll: undefined,
  positionSetter: () => {},
  position: 0,
  languageChange: () => {},
  language: "",
  Loader: () => {},
  loader: "",
  fetchDone: () => {},
  doneFetch: false,
  isEcomerce: () => {},
  ecomerce: false,
  navPageSecretM: () => {},
  navPage: false,
});

function ContentProvider({ children }) {
  const [scroll, setScroll] = useState(null);
  const [position, setPosition] = useState(0);
  const [language, setLanguage] = useState("it");
  const [loader, setLoader] = useState(true);
  const [doneFetch, setDoneFetch] = useState(false);
  const [ecomerce, setEcomerce] = useState(false);
  const [navPage, setNavPage] = useState(false);

  const setScrollTo = (state) => {
    setScroll(state);
  };
  const positionSetter = (state) => {
    setPosition(state);
  };
  const languageChange = (state) => {
    setLanguage(state);
  };
  const Loader = (state) => {
    setLoader(state);
  };
  const fetchDone = (state) => {
    setDoneFetch(state);
  };
  const isEcomerce = (state) => {
    setEcomerce(state);
  };
  const navPageSecretM = (state) => {
    setNavPage(state);
  };
  const value = {
    setScrollTo: setScrollTo,
    scroll: scroll,
    positionSetter: positionSetter,
    position: position,
    languageChange: languageChange,
    language: language,
    Loader: Loader,
    loader: loader,
    fetchDone: fetchDone,
    doneFetch: doneFetch,
    isEcomerce: isEcomerce,
    ecomerce: ecomerce,
    navPageSecretM: navPageSecretM,
    navPage: navPage,
  };
  return (
    <ScrollbarContext.Provider value={value}>
      {children}
    </ScrollbarContext.Provider>
  );
}
export default ContentProvider;
