import blackhole from "../svgs/blackhole.svg";
import right_arrow from "../images/right-arrow.svg";
import galaxy from "../svgs/GALASSIA SFONDO.png";
import red_background from "../svgs/SFONDO ROSSO BUCO NERO.png";
import astro from "../svgs/STELLE BUCO NERO.svg";
import star_1 from "../svgs/../svgs/Stelle.svg";
import star_2 from "../svgs/../svgs/Stelle.svg";
import { useContext, useEffect, useState } from "react";
import { ScrollbarContext } from "../store";
import { GET } from "../https";
import ReactMarkdown from "react-markdown";
import { Link, useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { problemiForward } from "../page_utile/pageTransition";

const Problemi = () => {
  const [fetchData, setfetchData] = useState({});
  const [fetchData2, setfetchData2] = useState({});
  const nav = useNavigate();
  const { scroll, language, positionSetter } = useContext(ScrollbarContext);
  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/home-page?locale=${language}&populate=*`);
        setfetchData(result);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    const fetchHome2 = async () => {
      try {
        const result = await GET(`/pages?locale=${language}&populate=*`);

        setfetchData2(result.data);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
    fetchHome2();
  }, [language]);

  function urlHander(id, http) {
    if (fetchData) {
      const seoTitle = http
        ? http[id]?.attributes.SEO.seoTitle
        : fetchData[id]?.attributes.SEO.seoTitle;

      return "/" + seoTitle;
    }
  }

  function kill() {
    gsap.to(".subproblemi", { display: "none" });
  }
  function ChiaTranstion() {
    setTimeout(() => {
      nav(urlHander(1, fetchData2));
      problemiForward();
      positionSetter(scroll.scrollTop);
    }, 50);

    kill();
  }
  return (
    <section className="problemi as">
      <p className="problemi__stuck-to-blackhole">
        {fetchData.data?.attributes.text_notizia}
      </p>

      <img className="problemi__star--1 a" src={star_1} alt="star" />
      <img className="problemi__star--2 a" src={star_2} alt="star" />
      <img className="problemi__galaxy" src={galaxy} alt="galaxy" />
      <img className="problemi__astro--1 a " src={astro} alt="astro" />
      <img className="problemi__astro--2 a" src={astro} alt="astro" />
      <img className="problemi__astro--3 a" src={astro} alt="astro" />
      <img className="problemi__astro--4 a" src={astro} alt="astro" />
      <img className="problemi__background" src={red_background} alt="galaxy" />
      <div className="problemi__together">
        <img src={blackhole} alt="blackhole" className="problemi__blackhole" />
        <div className="problemi__wrapper">
          <h2 className="problemi__wrapper--title">
            {fetchData.data?.attributes.title_serve}
          </h2>
          <p className="problemi__wrapper--text">
            <ReactMarkdown>
              {fetchData.data?.attributes.desc_serve}
            </ReactMarkdown>
          </p>
          <div className="clickable" onClick={ChiaTranstion}>
            <Link className="problemi__wrapper--arrow-text">
              {language === "en" ? "Find out more" : "Scopri di più"}
            </Link>
            <img
              className="problemi__wrapper--right-arrow"
              src={right_arrow}
              alt="right arrow"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Problemi;
