import {
  benefici,
  chai,
  contact,
  home,
  problemi,
  service,
} from "../gsapAinmation/scrollTo";
import menu_shadow from "../svgs/GRADIENT NERO SFONDO MENU-min.svg";
import { useContext } from "react";
import { ScrollbarContext } from "../store";

const Menu = () => {
  const { scroll, language } = useContext(ScrollbarContext);

  return (
    <div className="menu">
      <img src={menu_shadow} alt="menu shadow" className="menu__shadow" />;
      <ul id="width">
        <li className="menu__item" onClick={home.bind(this, scroll)}>
          <span>{"Home"}</span>
        </li>
        <li className="menu__item" onClick={chai.bind(this, scroll)}>
          <span>{language === "it" ? "Chi Siamo" : "About us"}</span>
        </li>
        <li className="menu__item" onClick={problemi.bind(this, scroll)}>
          <span>{language === "it" ? "Problemi" : "Problems"}</span>
        </li>
        <li className="menu__item odd" onClick={service.bind(this, scroll)}>
          <span>{language === "it" ? "Servizi" : "Services"}</span>
        </li>
        <li className="menu__item odd" onClick={benefici.bind(this, scroll)}>
          <span className="odd">
            {language === "it" ? "Benefici" : "Benefits"}
          </span>
        </li>
        <li className="menu__item" onClick={contact.bind(this, scroll)}>
          <span>{language === "it" ? "Contattaci" : "Contact"}</span>
        </li>
        <span className="menu__track"></span>
      </ul>
    </div>
  );
};

export default Menu;
