import { gsap } from "gsap";
import tlHome from "./time-line";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const phone = window.matchMedia("(max-width:500px)");
export const ServiceOnScroll = (phone) => {
  const windowHeight = window.innerHeight;

  gsap.to(".service__star2", { transform: "rotate(190deg)" });

  const service = {
    target: "#scroll",
    start: 3000,
    end: "+=400",
    scrub: true,
    onEnter: () => {
      if (phone.matches) {
        gsap.to(".problemi", { opacity: 0.001 });
      }
    },
    onLeaveBack: () => {
      if (phone.matches) {
        gsap.to(".problemi", { opacity: 1 });
      }
    },
  };
  const serviceLeave = {
    target: "#scroll",
    start: 3560,
    end: "+=400",
    scrub: true,
  };
  ScrollTrigger.matchMedia({
    "(min-width:800px)": function () {
      tlHome.fromTo(
        ".menu__track",
        { width: 320 },
        { width: 345, scrollTrigger: service }
      );
    },
    "(max-width:799px)": function () {
      tlHome.fromTo(".menu", { x: -300 }, { x: -400, scrollTrigger: service });
    },
    all: function () {
      tlHome
        .to(".service", {
          display: "flex",

          scrollTrigger: service,
        })

        .fromTo(
          ".service__star2",
          {
            opacity: 0,
          },
          {
            opacity: 1,
            scrollTrigger: service,
          }
        )
        .fromTo(
          ".service__star3",
          {
            opacity: 0,
          },
          {
            yPercent: 0,

            opacity: 1,
            scrollTrigger: service,
          }
        )

        .fromTo(
          ".service__blue-gas",
          { scale: 0.4, opacity: 0 },
          { scale: 3.3, opacity: 0.8, scrollTrigger: service }
        )
        // .fromTo(
        //   ".service__blue",
        //   {
        //     scale: 0.4,
        //     opacity: 0,
        //   },
        //   { scale: 1, opacity: 0.78, scrollTrigger: service }
        // )
        .fromTo(
          ".service__text-wrapper",
          { scale: 0, opacity: 0 },
          { scale: 1, opacity: 1, scrollTrigger: service }
        )
        .fromTo(
          ".service__galaxy",
          { yPercent: 100 },
          { yPercent: -10, scrollTrigger: service }
        )

        .fromTo(
          ".service__text-wrapper",
          { scale: 1, y: 0 },
          { scale: 1.2, y: -windowHeight + "px", scrollTrigger: serviceLeave }
        )

        .to(
          ".service__galaxy",

          {
            y: -windowHeight - 300,
            xPercent: -30,
            opacity: 0,
            scrollTrigger: serviceLeave,
          }
        )
        .fromTo(
          ".subproblemi__background",
          {
            opacity: 0.3,
          },
          {
            opacity: 0,
            scrollTrigger: serviceLeave,
          }
        );
      tlHome.fromTo(
        ".menu__track",
        { width: 320 },
        { width: 345, scrollTrigger: service }
      );
    },
  });
};
