const Checkbox = ({ label, children, checked, setchecked, ...props }) => {
  return (
    <div className="checkbox-wrapper">
      <label
        style={{ display: "flex", alignItems: "center", lineHeight: 1.3 }}
        className={checked ? "text" : "text notchecked"}>
        <input
          className={checked ? "checked" : ""}
          {...props}
          type="checkbox"
          checked={checked}
          onChange={() => {
            setchecked((prev) => !prev);
          }}
        />
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
