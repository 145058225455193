import gasEffect1 from "../svgs/GRADIENT SFONDO.svg";
import darkArea from "../svgs/GRADIENT SFONDO 2.svg";
import { gsap, ScrollTrigger } from "gsap/all";
import home_background from "../svgs/BACKGROUND BLU.svg";
import astroy from "../svgs/STELLA BUCO NERO.png";
import star_suprobmi from "../svgs/../svgs/Stelle.svg";
import { useContext, useEffect, useState } from "react";
import { ScrollbarContext } from "../store";
import hallow from "../svgs/hallow-blackhole.svg";
import { GET } from "../https";
gsap.registerPlugin(ScrollTrigger);
const Subproblemi = () => {
  const [fetchData, setfetchData] = useState({});
  const { language } = useContext(ScrollbarContext);
  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/home-page?locale=${language}&populate=*`);
        setfetchData(result);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
  }, [language]);
  return (
    <section className="subproblemi">
      <img
        className="subproblemi__hallow"
        src={hallow}
        alt="hallow blackhole"
      />
      <img src={astroy} alt="astroy" className="subproblemi__astroy" />
      <img className="subproblemi__star2" src={star_suprobmi} alt="star" />
      <img
        src={home_background}
        alt="home background"
        className="subproblemi__background"
      />

      <img
        className="subproblemi__gas-effect1"
        src={gasEffect1}
        alt="gas-effect"
      />
      <p className="subproblemi__stuck-to-blackholeNext">
        {fetchData.data?.attributes.text_rotta}
      </p>

      <img src={darkArea} alt="dark-area" className="subproblemi__dark-area" />
    </section>
  );
};

export default Subproblemi;
