import brand from "../svgs/Personal Brand & Brand Identity.svg";
import tele from "../svgs/Ricerche e Analisi.svg";
import content from "../svgs/Content Creation.svg";
import social from "../svgs/Social Media Management.svg";
import KPI from "../svgs/KPI e Risultati.svg";
import advertising from "../svgs/Advertising.svg";
import web from "../svgs/Siti Web & E-Commerce.svg";
import email from "../svgs/E-mail Marketing.svg";
import stratgies from "../svgs/STRATEGIE.svg";
import { useNavigate } from "react-router";
import { gsap } from "gsap";
import { useContext, useEffect, useState } from "react";
import { ScrollbarContext } from "../store";
import { GET } from "../https";
import right_arrow from "../images/right-arrow.svg";
import { Link } from "react-router-dom";

const ServiceModules = () => {
  const [fetchData, setfetchData] = useState([]);
  const [isload, setIsLoad] = useState(false);
  const { language } = useContext(ScrollbarContext);
  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/services?locale=${language}&populate=*`);
        setfetchData(result.data);
        setTimeout(() => {
          setIsLoad(true);
        }, 1500);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
  }, [language]);

  function urlHander(id) {
    if (fetchData) {
      const seoTitle = fetchData[id]?.attributes.SEO.seoTitle;
      return "/" + seoTitle;
    }
  }

  const nav = useNavigate();
  const { scroll, positionSetter } = useContext(ScrollbarContext);
  function kill() {
    gsap.to(".benefici", { display: "none" });
    gsap.to(".rece", { display: "none" });
    gsap.to(".contact", { display: "none" });
  }
  function brandTranstion() {
    nav(urlHander(0));

    gsap.to(".servizi", { xPercent: -130, duration: 4 });
    setTimeout(() => {
      positionSetter(scroll.scrollTop);
    }, 50);
    gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    kill();
  }
  function teleTranstion() {
    nav(urlHander(1));

    gsap.to(".servizi", { xPercent: -130, duration: 4 });
    setTimeout(() => {
      positionSetter(scroll.scrollTop);
    }, 50);
    gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    kill();
  }
  function straTranstion() {
    nav(urlHander(2));
    gsap.to(".servizi", { xPercent: -130, duration: 4 });

    setTimeout(() => {
      positionSetter(scroll.scrollTop);
    }, 50);
    gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    kill();
  }
  function contentTranstion() {
    nav(urlHander(3));
    gsap.to(".servizi", { xPercent: -130, duration: 4 });
    setTimeout(() => {
      positionSetter(scroll.scrollTop);
    }, 50);
    gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    kill();
  }
  function socialTranstion() {
    nav(urlHander(4));

    gsap.to(".servizi", { xPercent: -130, duration: 4 });
    setTimeout(() => {
      positionSetter(scroll.scrollTop);
    }, 50);
    gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    kill();
  }
  function adTranstion() {
    nav(urlHander(5));

    gsap.to(".servizi", { xPercent: -130, duration: 4 });
    setTimeout(() => {
      positionSetter(scroll.scrollTop);
    }, 50);
    gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    kill();
  }
  function emailTranstion() {
    nav(urlHander(6));

    gsap.to(".servizi", { xPercent: -130, duration: 4 });
    setTimeout(() => {
      positionSetter(scroll.scrollTop);
    }, 50);
    gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    kill();
  }
  function kpiTranstion() {
    nav(urlHander(7));

    gsap.to(".servizi", { xPercent: -130, duration: 4 });
    setTimeout(() => {
      positionSetter(scroll.scrollTop);
    }, 50);
    gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    kill();
  }
  function webTranstion() {
    nav(urlHander(8));

    gsap.to(".servizi", { xPercent: -130, duration: 4 });
    positionSetter(scroll.scrollTop);
    gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    kill();
  }
  return (
    <section className="servizi">
      {fetchData[0]?.attributes && isload && (
        <div className="col">
          <div className="servizi__container-1">
            <div className="servizi__brand">
              <img src={brand} alt="brand" />

              <h3>{fetchData[0]?.attributes.title}</h3>
              <p>{fetchData[0]?.attributes.subtitle}</p>
              <div onClick={brandTranstion} className="clickable">
                <Link className="problemi__wrapper--arrow-text">
                  {language === "en" ? "Find out more" : "Scopri di più"}
                </Link>
                <img
                  style={{ height: 29.7, width: 19.8 }}
                  className="problemi__wrapper--right-arrow"
                  src={right_arrow}
                  alt="right arrow"
                />
              </div>
            </div>
            <div className="servizi__tele">
              <img src={tele} alt="tele" />

              <h3>{fetchData[1]?.attributes.title}</h3>
              <p>{fetchData[1]?.attributes.subtitle}</p>
              <div onClick={teleTranstion} className="clickable">
                <Link className="problemi__wrapper--arrow-text">
                  {language === "en" ? "Find out more" : "Scopri di più"}
                </Link>
                <img
                  style={{ height: 29.7, width: 19.8 }}
                  className="problemi__wrapper--right-arrow"
                  src={right_arrow}
                  alt="right arrow"
                />
              </div>
            </div>
          </div>

          <div className="servizi__container-2">
            <div className="servizi__stragey">
              <img src={stratgies} onClick={straTranstion} alt="social" />

              <h3>{fetchData[2]?.attributes.title}</h3>
              <p>{fetchData[2]?.attributes.subtitle}</p>
              <div onClick={straTranstion} className="clickable">
                <Link className="problemi__wrapper--arrow-text">
                  {language === "en" ? "Find out more" : "Scopri di più"}
                </Link>
                <img
                  style={{ height: 29.7, width: 19.8 }}
                  className="problemi__wrapper--right-arrow"
                  src={right_arrow}
                  alt="right arrow"
                />
              </div>
            </div>
            <div className="servizi__content">
              <img src={content} alt="centent" />

              <h3>{fetchData[3]?.attributes.title}</h3>
              <p>{fetchData[3]?.attributes.subtitle}</p>
              <div onClick={contentTranstion} className="clickable">
                <Link className="problemi__wrapper--arrow-text">
                  {language === "en" ? "Find out more" : "Scopri di più"}
                </Link>
                <img
                  style={{ height: 29.7, width: 19.8 }}
                  className="problemi__wrapper--right-arrow"
                  src={right_arrow}
                  alt="right arrow"
                />
              </div>
            </div>
          </div>
          <div className="servizi__container-3">
            <div className="servizi__social">
              <img src={social} alt="social" />

              <h3>{fetchData[4]?.attributes.title}</h3>
              <p>{fetchData[4]?.attributes.subtitle}</p>
              <div onClick={socialTranstion} className="clickable">
                <Link className="problemi__wrapper--arrow-text">
                  {language === "en" ? "Find out more" : "Scopri di più"}
                </Link>
                <img
                  style={{ height: 29.7, width: 19.8 }}
                  className="problemi__wrapper--right-arrow"
                  src={right_arrow}
                  alt="right arrow"
                />
              </div>
            </div>
            <div className="servizi__advertising">
              <img src={advertising} alt="ad" />

              <h3>{fetchData[5]?.attributes.title}</h3>
              <p>{fetchData[5]?.attributes.subtitle}</p>
              <div onClick={adTranstion} className="clickable">
                <Link className="problemi__wrapper--arrow-text">
                  {language === "en" ? "Find out more" : "Scopri di più"}
                </Link>
                <img
                  style={{ height: 29.7, width: 19.8 }}
                  className="problemi__wrapper--right-arrow"
                  src={right_arrow}
                  alt="right arrow"
                />
              </div>
            </div>
          </div>
          <div className="servizi__container-4">
            <div className="servizi__email">
              <img src={email} alt="email" />

              <h3>{fetchData[6]?.attributes.title}</h3>
              <p>{fetchData[6]?.attributes.subtitle}</p>
              <div onClick={emailTranstion} className="clickable">
                <Link className="problemi__wrapper--arrow-text">
                  {language === "en" ? "Find out more" : "Scopri di più"}
                </Link>
                <img
                  style={{ height: 29.7, width: 19.8 }}
                  className="problemi__wrapper--right-arrow"
                  src={right_arrow}
                  alt="right arrow"
                />
              </div>
            </div>
            <div className="servizi__kpi">
              <img src={KPI} alt="kpi" />

              <h3> {fetchData[7]?.attributes.title}</h3>
              <p>{fetchData[7]?.attributes.subtitle}</p>
              <div onClick={kpiTranstion} className="clickable">
                <Link className="problemi__wrapper--arrow-text">
                  {language === "en" ? "Find out more" : "Scopri di più"}
                </Link>
                <img
                  style={{ height: 29.7, width: 19.8 }}
                  className="problemi__wrapper--right-arrow"
                  src={right_arrow}
                  alt="right arrow"
                />
              </div>
            </div>
          </div>
          <div className="servizi__container-5">
            <div className="servizi__web">
              <img src={web} alt="web" />

              <h3>{fetchData[8]?.attributes.title}</h3>
              <p>{fetchData[8]?.attributes.subtitle}</p>
              <div onClick={webTranstion} className="clickable">
                <Link className="problemi__wrapper--arrow-text">
                  {language === "en" ? "Find out more" : "Scopri di più"}
                </Link>
                <img
                  style={{ height: 29.7, width: 19.8 }}
                  className="problemi__wrapper--right-arrow"
                  src={right_arrow}
                  alt="right arrow"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ServiceModules;
