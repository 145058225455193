import { useRef } from "react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { ScrollbarContext } from "../store";
import Form from "../components/form";

const ContactPage = () => {
  const { language } = useContext(ScrollbarContext);
  const [enable, setEnable] = useState(false);
  const [showAndroidKey, setShowAndroidKey] = useState(false);
  const inputElement = useRef(null);

  useEffect(() => {
    if (navigator.userAgent.indexOf("Android") !== -1) {
      setShowAndroidKey(true);
      inputElement.current?.focus();
    }
  }, [showAndroidKey]);

  setTimeout(() => {
    setEnable(true);
  }, 3000);
  return (
    <>
      <Form
        language={language}
        enable={enable}
        inputElement={inputElement}
        isChcked={true}
        setIsChecked={true}
        isCheckbox={false}
        isWeb={true}
      />
    </>
  );
};

export default ContactPage;
