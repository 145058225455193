import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import tlHome from "./time-line";
import { rotateCost } from "../../gsapAinmation/gaspAimation";
import { tlcost } from "./time-line";
import { SlowMo } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
function ChiaOnScroll() {
  // gsap.to(".chia__star", {
  //   scale: 0.5,
  //   transform: "rotate(180deg)",
  // });
  const chiStrigger = {
    trigger: "#smooth",
    start: 10,
    end: "+=400",
    scrub: 0.4,

    onEnter: () => {
      tlcost.play();

      tlcost.to(".chia__cost", {
        rotate: "-12deg",
        delay: 3,
      });
      tlcost.to(".home__star2", { x: -200 }, "<");
      tlcost.to(".chia__galaxy", { x: 200, y: 0 }, "<");
    },

    onEnterBack: () => {
      tlcost.restart();
      tlcost.pause();
    },
  };
  const chiaLeaveStrigger = {
    trigger: "#smooth",
    start: 700,
    end: "+=400",
    scrub: true,
    onEnter: () => {
      tlcost.pause();
    },
    onLeaveBack: () => {
      tlcost.play();
    },
  };

  tlHome
    .to(".chia", {
      visibility: "visible",
      scrollTrigger: chiStrigger,
    })

    .fromTo(
      ".chia__gas-blue",
      { yPercent: -20, scale: 0.5, opacity: 0 },
      { scale: 2.5, opacity: 0.6, scrollTrigger: chiStrigger }
    )
    .fromTo(
      ".chia__together",
      { scale: 0, opacity: 0 },
      {
        scale: 1,
        opacity: 1,
        scrollTrigger: chiStrigger,
        onComplete: rotateCost,
      }
    )

    .fromTo(
      ".chia__galaxy",
      { yPercent: 150, scale: 2 },
      { yPercent: -35, scrollTrigger: chiStrigger }
    )

    .to(".chia__text-wrapper", {
      scale: 2,
      opacity: 0,
      scrollTrigger: chiaLeaveStrigger,
      xPercent: 100,
    })
    .to(
      ".chia__cost",

      {
        scale: 2,
        xPercent: -100,
        opacity: 0,
        scrollTrigger: chiaLeaveStrigger,
      }
    )
    .to(".chia__galaxy", {
      top: "1000px",
      opacity: 0,
      scrollTrigger: chiaLeaveStrigger,
    })
    .fromTo(
      ".header__astroy",
      { opacity: 1 },
      { opacity: 0, scrollTrigger: chiaLeaveStrigger }
    );
  // .fromTo(
  //   ".chia__star",
  //   { opacity: 1, scale: 0.8 },
  //   {
  //     scale: 2,
  //     opacity: 0,
  //     scrollTrigger: chiaLeaveStrigger,
  //   }
  // );
}

export default ChiaOnScroll;
