import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import tlHome from "./time-line";
const phone = window.matchMedia("(max-width: 799px)");

function BeneficiOnScroll(phone) {
  ScrollTrigger.matchMedia({
    "(min-width:800px)": function () {
      const benefici = {
        target: "#smooth",
        start: 4900,
        end: "+=400",
        scrub: true,
      };
      const benefici2 = {
        target: "#smooth",
        start: 5600,
        end: "+=400",
        scrub: true,
      };
      const beneficiD = {
        target: "#smooth",
        start: 5650,
        end: "+=400",
        scrub: true,
      };
      const beneficiLeave = {
        target: "#smooth",
        start: 5800,
        end: "+=400",
        scrub: true,
      };
      const beneficiLeave2 = {
        target: "#smooth",
        start: 6000,
        end: "+=700",
        scrub: true,
      };
      tlHome
        .fromTo(
          ".benefici",
          { visibility: "visible", y: 1000 },
          {
            y: 0,
            scrollTrigger: benefici,
          }
        )

        .to(".benefici__mirror", { xPercent: 130, scrollTrigger: benefici2 })

        .fromTo(
          ".benefici__text-wrapper",
          {
            width: "50%",
            x: -document.body.clientWidth * 0.08,
            clipPath: "polygon(0 0, 100% 0%, 100% 100%, 5% 100%)",
          },
          {
            width: 0,
            x: document.body.clientWidth * 0.35,
            clipPath: "polygon(0 0, 100% 0%, 100% 100%, 30% 100%)",
            scrollTrigger: beneficiD,
          }
        )
        .to(".benefici__mirror", {
          transform: "rotate3d(1, 100, 2, 65deg)",
          scrollTrigger: beneficiLeave,
        })
        .to(".benefici__mirror", {
          x: 1000,
          scrollTrigger: beneficiLeave2,
        });
      //567
      tlHome.fromTo(
        ".menu__track",
        { width: 460 },
        { width: 457, scrollTrigger: benefici }
      );
    },
    "(max-width:799px": function () {
      const benefici = {
        target: "#smooth",
        start: 5000,
        end: "+=400",
        scrub: true,
      };
      const benefici2 = {
        target: "#smooth",
        start: 5300,
        end: "+=400",
        scrub: true,
      };
      const beneficiD = {
        target: "#smooth",
        start: 5450,
        end: "+=400",
        scrub: true,
      };
      tlHome
        .fromTo(
          ".benefici",
          { visibility: "visible", y: 1000 },
          {
            y: 50,
            scrollTrigger: benefici,
          }
        )

        .to(".benefici__mirror", { opacity: 0, scrollTrigger: benefici2 })

        .fromTo(
          ".benefici__text-wrapper",
          { opacity: 1 },
          {
            opacity: 0,
            scrollTrigger: beneficiD,
          }
        );
      //-650
      tlHome.fromTo(".menu", { x: -400 }, { x: -505, scrollTrigger: benefici });
      // tlHome.fromTo(
      //   ".menu__track",
      //   { width: 490 },
      //   { width: 567, scrollTrigger: benefici }
      // );
    },
    // all: function () {

    // },
  });
}
export default BeneficiOnScroll;
