import { gsap } from "gsap";
import { chiaSimoaURL, problemiURL } from "../URL";
import { chiaForward, problemiForward, toEcomerce } from "./pageTransition";
import { easeInOutSine } from "../gsapAinmation/easing";

export const onReloadPage = (endpoint, scrollTop, scroll, positionSetter) => {
  if (window.location.href === window.location.origin + endpoint) {
    if (scroll) {
      scroll.scrollTo(0, scrollTop, 0);
      if (scroll?.scrollTop === 0) {
        toEcomerce(scroll);
      }

      if (scrollTop < 700 && scrollTop > 200) {
        chiaForward();
        positionSetter(scroll.scrollTop);
      } else if (scrollTop > 700 && scrollTop < 1500) {
        problemiForward();
        positionSetter(scroll.scrollTop);
      } else {
        gsap.to(".servizi", { xPercent: -130, display: "block", duration: 2 });
        positionSetter(scroll.scrollTop);
        gsap.to(".benefici", { display: "none" });
        gsap.to(".rece", { display: "none" });
        gsap.to(".contact", { display: "none" });
      }
      gsap.to(".menu", { yPercent: 100, ease: "Expo.easeInOut" });
    }
  }
};

export const pageHandler = (endpoint, setShow) => {
  window.onload = () => {
    if (window.location.href === window.location.origin + endpoint) {
      setShow(false);
      setTimeout(() => {
        setShow(true);
      }, 100);
    }
  };
};

export function restore() {
  gsap.to(".benefici", { display: "flex" });
  gsap.to(".rece", { display: "flex" });
  gsap.to(".contact", { display: "flex" });
}

export function ScrollEvent(url, scroll, refBrand, position) {
  setTimeout(() => {
    if (window.location.href === window.location.origin + url) {
      if (scroll.scrollTop < position) {
        scroll.scrollTo(0, position);
      }
      if (scroll.scrollTop > position + refBrand.current?.clientHeight) {
        scroll.scrollTo(0, position + refBrand.current?.clientHeight);
      }
    }
  }, 100);
}

export function ScrollToContact(scroll, position, nav) {
  gsap.to(".service-detail", { opacity: 0, duration: 2 });
  setTimeout(() => gsap.to(".servizi", { xPercent: 0, duration: 2 }), 300);
  scroll.scrollTo(0, position);

  gsap.to(".menu", { yPercent: 0, ease: "Expo.easeInOut" });
  setTimeout(() => nav("/"), 2000);
  setTimeout(() => {
    scroll.scrollTo(0, 6700, 4000, {
      easing: easeInOutSine,
    });
  }, 2100);
}

export function urlHander(fetchData) {
  if (fetchData) {
    const seoTitle = fetchData;
    var encodedText = encodeURI(seoTitle);
    return "/" + encodedText;
  }
}
