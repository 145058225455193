import contact_blue from "../svgs/GRADIENT STELLE PRIMA DEI PACCHETTI.svg";
import arrow_down from "../images/arrow-down.svg";
import { useContext, useEffect, useState } from "react";
import { ScrollbarContext } from "../store";
import { GET } from "../https";
const Contact = () => {
  const { language, scroll } = useContext(ScrollbarContext);
  const [fetchData, setfetchData] = useState({});
  const { title_contact } = fetchData;

  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/home-page?locale=${language}&populate=*`);
        setfetchData(result?.data.attributes);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
  }, [language]);

  return (
    <section className="contact">
      <img
        className="contact__blue"
        src={contact_blue}
        alt="contact blue background"
      />
      <h2 className="contact__text">
        {title_contact !== "undefined" ? title_contact : ""}
      </h2>
      <img
        className="contact__arrow"
        onClick={() => scroll.scrollTo(0, 6700, 4000)}
        src={arrow_down}
        alt="arrow-down"
      />
    </section>
  );
};

export default Contact;
