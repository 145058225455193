import mirror from "../svgs/PORTALE-min.svg";
import star from "../svgs/Stelle.svg";
import { ScrollbarContext } from "../store";
import { useContext, useEffect, useState } from "react";
import { GET } from "../https";
import ReactMarkdown from "react-markdown";

const Benefici = () => {
  const { language } = useContext(ScrollbarContext);
  const [fetchData, setfetchData] = useState({});
  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/home-page?locale=${language}&populate=*`);
        setfetchData(result);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
  }, [language]);
  return (
    <section className="benefici">
      {/* <img className="benefici__star2" src={star} alt="star" /> */}
      <img className="benefici__mirror" src={mirror} alt="mirror" />
      <div className="benefici__text-wrapper">
        <h2 className="benefici__text-wrapper--title">
          {" "}
          {fetchData?.data?.attributes.title_vantaggi}
        </h2>
        <div className="fix">
          <p className="benefici__text-wrapper--text">
            <ReactMarkdown>
              {fetchData?.data?.attributes.desc_vantaggi}
            </ReactMarkdown>
          </p>
          <div className="space"></div>
        </div>
      </div>
    </section>
  );
};

export default Benefici;
