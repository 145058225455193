import { motion } from "framer-motion";

const animations = {
  initial: {
    x: "100%",
    zIndex: 100000000,
    position: "aboslute",
  },
  animate: { x: "0%" },
};

const AnimatedPage = ({ children }) => {
  return (
    <motion.div
      className="service-detail"
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 2 }}>
      {children}
    </motion.div>
  );
};

export default AnimatedPage;
