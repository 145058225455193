import { ScrollbarContext } from "../store";
import { useContext, useEffect, useState } from "react";
import { GET } from "../https";
import ReactMarkdown from "react-markdown";

import trustpilot from "../svgs/TrustpilotStars.svg";
const Rece = () => {
  const { language } = useContext(ScrollbarContext);
  const [fetchData, setfetchData] = useState({});
  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/home-page?locale=${language}&populate=*`);
        setfetchData(result);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
  }, [language]);
  return (
    <section className="rece">
      <h2 className="rece__title">
        {fetchData?.data?.attributes.title_dicono}
      </h2>

      <p className="rece__text">
        <ReactMarkdown>{fetchData?.data?.attributes.desc_dicono}</ReactMarkdown>
      </p>
      <img
        style={{ width: "30%", height: "30%" }}
        src={trustpilot}
        alt="trustpilot logo"
      />
    </section>
  );
};

export default Rece;
