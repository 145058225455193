import { Power4, SlowMo } from "gsap/all";
const steps = {
  0: "home",
  1: "chia",
  2: "problemi",
  3: "service",
  4: "siti",
  5: "benefici",
  6: "rece",
  7: "contact",
};
const phone = window.matchMedia("(max-width:799px)");
let track = 0;
export function home(scroll) {
  if (steps[track] === "chia") {
    scroll.scrollTo(0, 0, 3000, {
      easing: SlowMo.ease.config(0.1, 0.1, false),
    });
  } else {
    scroll.scrollTo(0, 0, 2000, {
      easing: Power4.easeOut,
    });
  }
  track = 0;
}
export function chai(scroll) {
  if (steps[track] === "home" || steps[track] === "problemi") {
    scroll.scrollTo(0, 600, 4000, {
      easing: SlowMo.ease.config(0.1, 0.1, false),
    });
  } else {
    scroll.scrollTo(0, 600, 2000, {
      easing: Power4.easeOut,
    });
  }
  track = 1;
}
export function problemi(scroll) {
  if (steps[track] === "chia" || steps[track] === "service") {
    scroll.scrollTo(0, 1200, 4000, {
      easing: SlowMo.ease.config(0.1, 0.1, false),
    });
  } else {
    scroll.scrollTo(0, 1200, 2000, {
      easing: Power4.easeOut,
    });
  }
  track = 2;
}
export function service(scroll) {
  if (steps[track] === "problemi") {
    scroll.scrollTo(0, 3340, 4000, {
      easing: Power4.easeOut,
    });
  } else {
    scroll.scrollTo(0, 3340, 3000, {
      easing: Power4.easeOut,
    });
  }
  track = 3;
}
export function siti(scroll) {
  if (steps[track] === "service") {
    scroll.scrollTo(0, 4850, 4000, {
      easing: Power4.easeOut,
    });
  } else {
    scroll.scrollTo(0, 4850, 3500, {
      easing: Power4.easeOut,
    });
  }
  track = 4;
}

export function benefici(scroll) {
  if (steps[track] === "siti") {
    scroll.scrollTo(0, 5400, 4000, {
      easing: SlowMo.ease.config(0.1, 0.1, false),
    });
  } else {
    scroll.scrollTo(0, 5400, 4000, {
      easing: Power4.easeOut,
    });
  }
  track = 5;
}
export function rece(scroll) {
  if (steps[track] === "benefici") {
    scroll.scrollTo(0, 6400, 4000, {
      easing: SlowMo.ease.config(0.1, 0.1, false),
    });
  } else {
    scroll.scrollTo(0, 6400, 4000, {
      easing: Power4.easeOut,
    });
  }
  track = 6;
}
export function contact(scroll) {
  if (steps[track] === "rece") {
    scroll.scrollTo(0, phone.matches ? 6200 : 6400, 4000, {
      easing: Power4.easeOut,
    });
  } else {
    scroll.scrollTo(0, phone.matches ? 6200 : 6400, 4000, {
      easing: Power4.easeOut,
    });
  }
  track = 7;
}
