import { Link } from "react-router-dom";
import React from "react";

const NotFound = () => {
  return (
    <main className="notfound">
      <h1 className="notfound__status">404</h1>
      <p className="notfound__description">
        You have visited a page which does not exist, please return to{" "}
        <Link to={window.location.origin}>{window.location.origin}</Link>
      </p>
    </main>
  );
};

export default NotFound;
