import React, { useEffect, useContext, useState, Fragment } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Main from "../../screens/home";
import { AnimatePresence } from "framer-motion";
import BrandPage from "../../screens/brand";
import TelescopePage from "../../screens/telescope";
import StratgiesPage from "../../screens/stratgies";
import ContentPage from "../../screens/content";
import SocialPage from "../../screens/social";
import AdPage from "../../screens/advertizing";
import EmailPage from "../../screens/email";
import KPIPage from "../../screens/kpi";
import WebPage from "../../screens/web";
import ChiaSimao_1 from "../../screens/chiaSimoa";
import ProblemiPage from "../../screens/problemiPage";
import ReactGA from "react-ga";
import { GET } from "../../https";
import { ScrollbarContext } from "../../store";
import NotFound from "../../screens/404";
import EComerece from "../../screens/siteEComerece";
import JoinUs from "../../screens/joinUs";

ReactGA.initialize("G-Q71DTPZPKM");

function NotFoundHandler() {
  const [time, setTime] = useState(false);
  setTimeout(() => {
    setTime(true);
  }, 1000);
  return <Fragment>{time && <NotFound />}</Fragment>;
}

const RouteComponent = () => {
  const [fetchData, setfetchData] = useState({});
  const [fetchData2, setfetchData2] = useState({});

  const { language, scroll } = useContext(ScrollbarContext);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/services?locale=${language}&populate=*`);
        setfetchData(result.data);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    const fetchHome2 = async () => {
      try {
        const result = await GET(`/pages?locale=${language}&populate=*`);

        setfetchData2(result.data);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
    fetchHome2();
  }, [language]);

  function urlHander(id, http) {
    if (fetchData) {
      const seoTitle = http
        ? http[id]?.attributes.SEO.seoTitle
        : fetchData[id]?.attributes.SEO.seoTitle;

      return "/" + seoTitle;
    }
  }
  return (
    <AnimatePresence mode="wait">
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path={urlHander(0)} element={<BrandPage />} />
          <Route path={urlHander(1)} element={<TelescopePage />} />
          <Route path={urlHander(2)} element={<StratgiesPage />} />
          <Route path={urlHander(3)} element={<ContentPage />} />
          <Route path={urlHander(4)} element={<SocialPage />} />
          <Route path={urlHander(5)} element={<AdPage />} />
          <Route path={urlHander(6)} element={<EmailPage />} />
          <Route path={urlHander(7)} element={<KPIPage />} />
          <Route
            path={urlHander(8)}
            element={scroll?.scrollTop < 3000 ? <EComerece /> : <WebPage />}
          />
          <Route path={urlHander(0, fetchData2)} element={<ChiaSimao_1 />} />
          <Route path={urlHander(1, fetchData2)} element={<ProblemiPage />} />
          <Route path={urlHander(3, fetchData2)} element={<JoinUs />} />
        </Route>
        <Route path="*" element={<NotFoundHandler />} />
      </Routes>
    </AnimatePresence>
  );
};

export default RouteComponent;
