import { useEffect } from "react";
import Scrollbar from "smooth-scrollbar";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { useState } from "react";
import { useContext } from "react";
import { ScrollbarContext } from "../store";

const Smooth = () => {
  const context = useContext(ScrollbarContext);
  const [scrollTo, setScrollTo] = useState(null);
  const [speed, setSpeet] = useState(0.08);

  useEffect(() => {
    if (scrollTo) {
      function scrollHandler() {
        context.setScrollTo(scrollTo);
      }
      scrollHandler();
    }
  }, [scrollTo]);

  useEffect(() => {
    const mobile = () => {
      if (window.innerWidth < 800) {
        setSpeet(0.05);
      } else {
        setSpeet(0.08);
      }
    };
    window.addEventListener("resize", mobile);
    mobile();
    gsap.registerPlugin(ScrollTrigger);
    // Setup
    const scroller = document.querySelector("#smooth");

    const bodyScrollBar = Scrollbar.init(document.body, {
      damping: speed,
      delegateTo: document,
    });
    bodyScrollBar.track.xAxis.element.remove();
    bodyScrollBar.track.yAxis.hide();

    setScrollTo(bodyScrollBar);

    ScrollTrigger.scrollerProxy(scroller, {
      scrollTop(value) {
        if (arguments.length) {
          bodyScrollBar.scrollTop = value;
        }
        return bodyScrollBar.scrollTop;
      },
    });

    bodyScrollBar.addListener(ScrollTrigger.update);
    ScrollTrigger.defaults({ scroller: scroller });

    return () => {
      bodyScrollBar.destroy();
      window.addEventListener("resize", mobile);
    };
  }, [speed]);
};

export default Smooth;
