import tlHome, { tlcost } from "./time-line";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
gsap.registerPlugin(ScrollTrigger);
const phone = window.matchMedia("(max-width:500px)");
function ProblemiOnScroll(phone) {
  const problemiStrigger = {
    trigger: "#smooth",
    start: 700,
    end: "+=400",
    scrub: true,
  };
  const problemiLeaveStrigger = {
    trigger: "#smooth",
    start: 1400,
    end: "+=400",
    scrub: true,
  };
  const star360 = {
    trigger: "#smooth",
    start: 800,
    end: "+=400",
    scrub: true,
  };
  ScrollTrigger.matchMedia({
    "(min-width:800px)": function () {},
    "(max-width:799px)": function () {
      // if (navigator.userAgent.indexOf("Android") !== -1) {
      //   tlHome.fromTo(
      //     ".menu__track",
      //     { width: "100px" },
      //     { width: "192px", scrollTrigger: problemiStrigger }
      //   );
      // } else {
      // tlHome.fromTo(
      //   ".menu__track",
      //   { width: "15%" },
      //   { width: "28%", scrollTrigger: problemiStrigger }
      // );
      // }
      tlHome.fromTo(
        ".menu",
        { x: -150 },
        { x: -230, scrollTrigger: problemiStrigger }
      );
    },
    all: function () {
      gsap.fromTo(
        ".problemi__blackhole",
        { scale: 1 },
        {
          scale: 1,
          scrollTrigger: problemiStrigger,
        }
      );

      tlHome
        .fromTo(
          ".problemi",
          {
            visibility: "hidden",

            opacity: 0,
            zIndex: 1,
          },
          {
            opacity: 1,
            visibility: "visible",
            scrollTrigger: problemiStrigger,
            zIndex: 5,
          }
        )
        .fromTo(
          ".problemi__together",
          { scale: 0 },
          {
            scale: 1,
            scrollTrigger: problemiStrigger,
          }
        )
        .fromTo(
          ".problemi",
          { zIndex: 5 },
          {
            scrollTrigger: problemiLeaveStrigger,
            zIndex: 5,
          }
        )

        .to(".problemi__background", {
          opacity: 0.3,
          scrollTrigger: problemiStrigger,
        })
        .fromTo(
          ".home__background",
          { opacity: 0.2 },
          { opacity: 0, scrollTrigger: problemiStrigger }
        )
        .to(".problemi__galaxy", {
          transformOrigin: "center",
          opacity: 0.5,
          xPercent: 90,
          scrollTrigger: problemiStrigger,
        })
        .fromTo(
          ".problemi__astro--1",
          { scale: 1, y: -document.body.clientHeight * 0.4 },
          {
            scale: 2,
            y: document.body.clientHeight * 0.25,
            x: document.body.clientWidth * 0.1,
            scrollTrigger: problemiStrigger,
          }
        )
        .fromTo(
          ".problemi__astro--2",
          { scale: 1, y: -document.body.clientHeight * 0.4 },
          {
            scale: 1.4,
            y: document.body.clientHeight * 0.25,
            x: -document.body.clientWidth * 0.1,

            scrollTrigger: problemiStrigger,
          }
        )
        .fromTo(
          ".problemi__astro--3",
          {
            scale: 1,
            y: -document.body.clientHeight * 0.1,
            x: document.body.clientWidth * 0.1,
          },
          {
            scale: 2,
            x: -document.body.clientWidth * 0.25,
            y: document.body.clientHeight * 0,

            scrollTrigger: problemiStrigger,
          }
        )
        .fromTo(
          ".problemi__astro--4",
          {
            scale: 1,
            y: document.body.clientHeight * 0.2,
            x: -document.body.clientWidth * 0.03,
          },
          {
            scale: 2,
            y: -document.body.clientHeight * 0.02,

            scrollTrigger: problemiStrigger,
          }
        )
        .to(".problemi__star--1", {
          scale: 1.5,
          opacity: 1,
          transformOrigin: "center",
          rotate: "250deg",
          scrollTrigger: star360,
        })
        .to(".problemi__star--2", {
          scale: 1.5,
          opacity: 1,
          rotate: "-300deg",
          scrollTrigger: star360,
        })

        .fromTo(
          ".problemi__astro--1",
          {
            scale: 2,
            yPercent: 140,
          },
          {
            scale: 1,
            y: -document.body.clientHeight * 0.5,
            x: -document.body.clientWidth * 0.3,

            scrollTrigger: problemiLeaveStrigger,
          }
        )
        .fromTo(
          ".problemi__astro--2",
          {
            scale: 1.4,
            yPercent: 140,
          },
          {
            scale: 1,
            y: -document.body.clientHeight * 0.52,
            x: -document.body.clientWidth * 0.1,

            scrollTrigger: problemiLeaveStrigger,
          }
        )
        .fromTo(
          ".problemi__astro--3",
          {
            scale: 2,
            x: -500,
          },
          {
            scale: 1,
            x: document.body.clientWidth * 0.07,

            scrollTrigger: problemiLeaveStrigger,
          }
        )
        .fromTo(
          ".problemi__astro--4",
          {
            scale: 2,
            yPercent: -100,
          },
          {
            scale: 1,
            y: document.body.clientHeight * 0.5,
            scrollTrigger: problemiLeaveStrigger,
          }
        );
      tlHome
        .to(
          ".problemi__blackhole",

          {
            scale: 1.2,
            xPercent: 14,
            transform: "rotate(1deg)",
            scrollTrigger: problemiLeaveStrigger,
          }
        )
        .to(".problemi__wrapper", {
          xPercent: -30,
          rotate: "10deg",
          transformOrigin: "left",
          scrollTrigger: problemiLeaveStrigger,
        })
        .fromTo(
          ".problemi__background",
          { opacity: 0.3 },
          { opacity: 0.7, scrollTrigger: problemiLeaveStrigger }
        )
        .fromTo(
          ".problemi",
          {
            zIndex: 4,
          },
          {
            scrollTrigger: problemiLeaveStrigger,
            zIndex: "unset",
          }
        );
      tlHome.fromTo(
        ".menu__track",
        { width: 122 },
        { width: 235, scrollTrigger: problemiStrigger }
      );
    },
  });
}

export default ProblemiOnScroll;
