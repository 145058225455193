import { useEffect, useState } from "react";
import tlHome from "./time-line";
import homeOnScroll from "./homeOnScroll";
import ChiaOnScroll from "./chiaOnScroll";
import ProblemiOnScroll from "./problemiOnScroll";
import SubPorblemi from "./sub-problemi";
import { ServiceOnScroll } from "./serviceOnScroll";
import ModuleOnScroll from "./moduleOnScroll";
import BeneficiOnScroll from "./beneficiOnScroll";
import Rece from "./receOnScroll";
import ContactOnScroll from "./contactOnScroll";

const ScrollT = () => {
  const phone = window.matchMedia("(max-width:500px)");
  const [scrollTriggerLoaded, setScrollTriggerLoaded] = useState(false);
  const intializer = () => {
    setTimeout(() => {
      homeOnScroll(phone);
    }, 10);
    setTimeout(() => {
      ChiaOnScroll(phone);
    }, 0);

    setTimeout(() => {
      ProblemiOnScroll(phone);
    }, 0);
    setTimeout(() => {
      SubPorblemi(phone);
    }, 0);
    setTimeout(() => {
      ServiceOnScroll(phone);
    }, 0);
    setTimeout(() => {
      ModuleOnScroll(phone);
    }, 0);
    setTimeout(() => {
      BeneficiOnScroll(phone);
    }, 0);
    // setTimeout(() => {
    //   Rece();
    // }, 0);
    setTimeout(() => {
      ContactOnScroll(phone);
    }, 0);
  };
  useEffect(() => {
    async function loadScrollTrigger() {
      await import("gsap/ScrollTrigger");
      setScrollTriggerLoaded(true);
    }

    if (scrollTriggerLoaded) {
      tlHome.from(".pin", {
        scrollTrigger: {
          trigger: "#smooth",
          start: "top top",
          end: "bottom bottom",
          pin: true,
          pinSpacing: false,
          anticipatePin: 1,
          once: true,
        },
      });
    }
    intializer();
    loadScrollTrigger();
  }, [scrollTriggerLoaded]);
};

export default ScrollT;
