import { gsap } from "gsap";
import tlHome from "./time-line";
import { ScrollTrigger } from "gsap/ScrollTrigger";
const phone = window.matchMedia("(max-width:500px)");

const SubPorblemi = (phone) => {
  const windowHeight = window.innerHeight;

  // gsap.set(".subproblemi__star2", { transform: "rotate(0deg)" });

  ScrollTrigger.matchMedia({
    "(min-width:800px)": function () {
      const blackholeExpand = {
        traget: "#scroll",
        start: 2000,
        end: "+=600",
        scrub: true,
      };
      const clipPath = {
        traget: "#scroll",
        start: 2600,
        end: "+=400",
        scrub: true,
      };
      const clipPath1 = {
        traget: "#scroll",
        start: 2600,
        end: "+=400",
        scrub: true,
      };
      const clipPath2 = {
        traget: "#scroll",
        start: 3000,
        end: "+=400",
        scrub: true,
      };

      tlHome
        .fromTo(
          ".problemi__blackhole",
          {
            scale: 1.2,
            y: 0,
            x: 0,
          },
          {
            scale: 12,
            rotation: 50,
            y: document.body.clientHeight * 0.2,
            x: document.body.clientWidth * 0.2,
            scrollTrigger: blackholeExpand,
          }
        )
        .to(
          ".problemi__stuck-to-blackhole",

          {
            opacity: 1,
            scale: 1,
            scrollTrigger: blackholeExpand,
          }
        )

        .fromTo(
          ".problemi__stuck-to-blackhole",
          { opacity: 1 },
          {
            scale: 5,
            y: -windowHeight,

            scrollTrigger: clipPath,
          }
        )
        .fromTo(
          ".subproblemi__stuck-to-blackholeNext",
          { opacity: 0.1, scale: 0.1 },
          {
            opacity: 1,
            scale: 1,
            scrollTrigger: clipPath1,
          }
        )

        .fromTo(
          ".subproblemi",
          { display: "flex", clipPath: "circle(0%)", opacity: 0 },
          {
            clipPath: "circle(130%)",
            opacity: 1,
            scrollTrigger: clipPath1,
          }
        )
        .fromTo(
          ".subproblemi__hallow",
          { scale: 0 },
          {
            scaleX: 4.6,
            scaleY: 4.6,
            scrollTrigger: clipPath1,
          }
        )
        .fromTo(
          ".subproblemi__hallow",
          { opacity: 1 },
          {
            opacity: 0,
            scrollTrigger: clipPath2,
          }
        )
        .fromTo(
          ".subproblemi__star2",
          { opacity: 0 },
          { opacity: 1, scrollTrigger: clipPath1 }
        )
        .fromTo(
          ".header__white-elipse",
          { opacity: 0 },
          { opacity: 0.4, scrollTrigger: clipPath1 }
        )
        .to(".subproblemi__astroy", {
          x: -630,
          y: 200,
          opacity: 0.8,
          scrollTrigger: clipPath2,
        })
        .fromTo(
          ".subproblemi__star2",
          {
            scale: 3.5,
            y: -windowHeight,
          },
          {
            scale: 6.5,
            opacity: 0,
            y: -windowHeight - 800,

            scrollTrigger: clipPath2,
          }
        )
        .fromTo(
          ".subproblemi__stuck-to-blackholeNext",
          { scale: 1 },
          {
            scale: 2.4,
            y: -windowHeight + 100 + "px",
            scrollTrigger: clipPath2,
          }
        )
        .fromTo(
          ".header__white-elipse",
          { opacity: 0.4 },
          { opacity: 0, scrollTrigger: clipPath2 }
        )
        .to(".subproblemi__gas-effect1", {
          opacity: 0,
          scrollTrigger: clipPath2,
        })
        .fromTo(
          ".subproblemi__background",
          { opacity: 0.8 },
          { opacity: 0.3, scrollTrigger: clipPath2 }
        );
      tlHome.fromTo(
        ".menu__track",
        { width: 235 },
        { width: 280, scrollTrigger: blackholeExpand }
      );
      tlHome.fromTo(
        ".menu__track",
        { width: 280 },
        { width: 320, scrollTrigger: clipPath }
      );
    },
    "(max-width:799px)": function () {
      const blackholeExpand = {
        traget: "#scroll",
        start: 2000,
        end: "+=400",
        scrub: true,
      };
      const clipPath = {
        traget: "#scroll",
        start: 2400,
        end: "+=600",
        scrub: true,
      };
      const clipPath1 = {
        traget: "#scroll",
        start: 2500,
        end: "+=400",
        scrub: true,
      };
      const clipPath2 = {
        traget: "#scroll",
        start: 2900,
        end: "+=400",
        scrub: true,
      };
      tlHome
        .fromTo(
          ".problemi__blackhole",
          {
            scale: 1.2,
            y: 0,
            x: 0,
          },
          {
            scale: 12,
            rotation: 50,
            y: document.body.clientHeight * 0.2,
            x: document.body.clientWidth * 0.2,
            scrollTrigger: blackholeExpand,
          }
        )
        .to(".problemi__stuck-to-blackhole", {
          opacity: 1,
          scale: 1,
          scrollTrigger: blackholeExpand,
        })

        .fromTo(
          ".problemi__stuck-to-blackhole",
          { opacity: 1 },
          {
            scale: 5,
            y: -windowHeight,

            scrollTrigger: clipPath,
          }
        )
        .fromTo(
          ".subproblemi__stuck-to-blackholeNext",
          { opacity: 0.1, scale: 0.1 },
          {
            opacity: 1,
            scale: 1,
            scrollTrigger: clipPath1,
          }
        )

        .fromTo(
          ".subproblemi",
          { display: "flex", clipPath: "circle(0%)", opacity: 0 },
          {
            clipPath: "circle(130%)",
            opacity: 1,
            scrollTrigger: clipPath1,
          }
        )
        .fromTo(
          ".subproblemi__hallow",
          { scale: 0 },
          {
            scaleX: 4,
            scaleY: 4,
            scrollTrigger: clipPath1,
          }
        )
        .fromTo(
          ".subproblemi__hallow",
          { opacity: 1 },
          {
            opacity: 0,
            scrollTrigger: clipPath2,
          }
        )
        .fromTo(
          ".subproblemi__star2",
          { opacity: 0 },
          { opacity: 1, scrollTrigger: clipPath1 }
        )
        .fromTo(
          ".header__white-elipse",
          { opacity: 0 },
          { opacity: 0.4, scrollTrigger: clipPath1 }
        )
        .to(".subproblemi__astroy", {
          x: -630,
          y: 200,
          opacity: 0.8,
          scrollTrigger: clipPath2,
        })
        .fromTo(
          ".subproblemi__star2",
          {
            scale: 1,
          },
          {
            scale: 1.5,
            opacity: 0,
            yPercent: -25,

            scrollTrigger: clipPath2,
          }
        )
        .fromTo(
          ".subproblemi__stuck-to-blackholeNext",
          { scale: 1 },
          {
            scale: 2.4,
            y: -windowHeight + 100 + "px",
            scrollTrigger: clipPath2,
          }
        )
        .fromTo(
          ".header__white-elipse",
          { opacity: 0.4 },
          { opacity: 0, scrollTrigger: clipPath2 }
        )
        .to(".subproblemi__gas-effect1", {
          opacity: 0,
          scrollTrigger: clipPath2,
        })
        .fromTo(
          ".subproblemi__background",
          { opacity: 0.6 },
          { opacity: 0.3, scrollTrigger: clipPath2 }
        );
      tlHome.fromTo(
        ".menu__track",
        { width: 235 },
        { width: 280, scrollTrigger: blackholeExpand }
      );

      // if (navigator.userAgent.indexOf("Android") !== -1) {
      //   tlHome.fromTo(
      //     ".menu__track",
      //     { width: "192px" },
      //     { width: "220px", scrollTrigger: blackholeExpand }
      //   );
      //   tlHome.fromTo(
      //     ".menu__track",
      //     { width: "220px" },
      //     { width: "250px", scrollTrigger: clipPath }
      //   );
      // } else {

      // tlHome.fromTo(
      //   ".menu__track",
      //   { width: "27%" },
      //   { width: "30%", scrollTrigger: blackholeExpand }
      // );
      // tlHome.fromTo(
      //   ".menu__track",
      //   { width: "30%" },
      //   { width: "36%", scrollTrigger: clipPath }
      // );
      tlHome.fromTo(
        ".menu",
        { x: -250 },
        { x: -300, scrollTrigger: blackholeExpand }
      );
      // }
    },
  });
};

export default SubPorblemi;
