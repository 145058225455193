import axios from "axios";

export const GET = async (endpoint) => {
  try {
    const result = await axios.get(process.env.REACT_APP_API_URL + endpoint, {
      headers: {
        Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
      },
    });
    return result.data;
  } catch (err) {
    console.log(err);
  }
};

export const POST = async (data, endpoint) => {
  try {
    await axios.post(process.env.REACT_APP_API_URL + endpoint, data, {
      headers: {
        Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
      },
    });
  } catch (err) {
    console.log("some error", err);
  }
};
