import { gsap } from "gsap";
import tlHome from "./time-line";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function ContactOnScroll() {
  ScrollTrigger.matchMedia({
    "(min-width:800px)": function () {
      const contact = {
        target: "#smooth",
        start: 6000,
        end: "+=400",
        scrub: true,
        onEnter: () => {
          gsap.to(".contact__arrow", { yPercent: 30, yoyo: true, repeat: 4 });
        },
      };
      const contactLeave = {
        target: "#smooth",
        start: 6400,
        end: "+=400",
        scrub: true,
      };
      const contactPKG = {
        target: "#smooth",
        start: 6400,
        end: "+=1200",
        scrub: true,
      };
      tlHome
        .to(".contact", {
          visibility: "visible",
          scrollTrigger: contact,
          willChange: "transform",
        })
        .fromTo(
          ".contact",
          { yPercent: 100, scale: 0.3, opacity: 0.2 },
          { yPercent: 0, opacity: 1, scale: 1, scrollTrigger: contact }
        )
        .fromTo(
          ".contact",
          { yPercent: 0, scale: 1, opacity: 1 },
          {
            yPercent: -100,
            opacity: 0.7,
            scale: 1.5,
            scrollTrigger: contactLeave,
          }
        )
        .to(".pkg", { visibility: "visible", scrollTrigger: contactPKG })
        .fromTo(
          ".pkg",
          { yPercent: 100 },
          { yPercent: -40, scrollTrigger: contactPKG }
        );
      tlHome.fromTo(
        ".menu__track",
        { width: 460 },
        { width: 567, scrollTrigger: contact }
      );

      // tlHome.fromTo(
      //   ".menu__track",
      //   { width: "79%" },
      //   { width: "91%", scrollTrigger: contact }
      // );
      // tlHome.fromTo(
      //   ".menu__track",
      //   { width: 567 },
      //   { width: 680, scrollTrigger: contact }
      // );
    },
    "(max-width:799px)": function () {
      const contact = {
        target: "#smooth",
        start: 5700,
        end: "+=400",
        scrub: true,
        onEnter: () => {
          gsap.to(".contact__arrow", { yPercent: 30, yoyo: true, repeat: 4 });
        },
      };
      const contactLeave = {
        target: "#smooth",
        start: 6200,
        end: "+=400",
        scrub: true,
      };
      const contactPKG = {
        target: "#smooth",
        start: 6200,
        end: "+=3000",
        scrub: true,
      };
      tlHome
        .to(".contact", {
          visibility: "visible",
          scrollTrigger: contact,
          willChange: "transform",
        })
        .fromTo(
          ".contact",
          { yPercent: 100, scale: 0.3, opacity: 0.2 },
          { yPercent: 0, opacity: 1, scale: 1, scrollTrigger: contact }
        )
        .fromTo(
          ".contact",
          { yPercent: 0, scale: 1, opacity: 1 },
          {
            yPercent: -100,
            opacity: 0.7,
            scale: 1.5,
            scrollTrigger: contactLeave,
          }
        )

        .to(".pkg", { visibility: "visible", scrollTrigger: contactPKG })
        .fromTo(
          ".pkg",
          { yPercent: 100 },
          { yPercent: -40, scrollTrigger: contactPKG }
        );
      tlHome.fromTo(
        ".menu__track",
        { width: 460 },
        { width: 567, scrollTrigger: contact }
      );

      // tlHome.fromTo(
      //   ".menu__track",
      //   { width: "81%" },
      //   { width: "95%", scrollTrigger: contact }
      // );
      // tlHome.fromTo(".menu", { x: -650 }, { x: -700, scrollTrigger: contact });
    },
  });
}
