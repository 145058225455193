import tlHome from "./time-line";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const ModuleOnScroll = () => {
  const windowHeight = window.innerHeight;

  ScrollTrigger.matchMedia({
    "(min-width:800px)": function () {
      const service = {
        target: "#scroll",
        start: 3350,
        end: "+=3000",
        scrub: true,

        onUpdate: (self) => {
          if (Math.abs(self.progress) > 0.68) {
            gsap.set(".menu__track", { width: 458 });
          }
        },
        onEnter: () => {
          gsap.to(".header__white-elipse", { display: "none" });
          gsap.to(".header__white-elipse--left", { display: "none" });
        },
        onLeaveBack: () => {
          gsap.to(".header__white-elipse", { display: "block" });
          gsap.to(".header__white-elipse--left", { display: "block" });
        },
      };
      tlHome
        .fromTo(
          ".servizi",
          {
            display: "block",
            yPercent: 100,
          },
          {
            yPercent: -100,
            scrollTrigger: service,
          }
        )
        .to(
          ".service__star2",

          { y: -windowHeight - 150 + "px", scrollTrigger: service }
        )
        .to(
          ".service__star3",

          { y: -windowHeight - 100 + "px", scrollTrigger: service }
        )

        // .fromTo(
        //   ".service__blue",
        //   { opacity: 0.8 },
        //   { opacity: 0, scrollTrigger: service }
        // )
        .to(".service__blue-gas", { xPercent: 20, scrollTrigger: service });
      //495
      tlHome.fromTo(
        ".menu__track",
        { width: 345 },
        { width: 460, scrollTrigger: service }
      );
    },
    "(max-width:799px)": function () {
      const service = {
        target: "#scroll",
        start: 3090,
        end: "+=4000",
        scrub: true,

        onUpdate: (self) => {
          if (Math.abs(self.progress) > 0.5) {
            gsap.set(".menu__track", { width: 458 });
          }
          if (Math.abs(self.progress) > 0.7) {
            gsap.set(".menu__track", {
              width: 567,
            });
          }
        },
        onEnter: () => {
          gsap.to(".header__white-elipse", { display: "none" });
          gsap.to(".header__white-elipse--left", { display: "none" });
        },
        onLeaveBack: () => {
          gsap.to(".header__white-elipse", { display: "block" });
          gsap.to(".header__white-elipse--left", { display: "block" });
        },
      };
      tlHome
        .fromTo(
          ".servizi",
          {
            display: "block",
            yPercent: 100,
          },
          {
            yPercent: -100,
            scrollTrigger: service,
          }
        )
        .to(
          ".service__star2",

          { y: -windowHeight - 100 + "px", scrollTrigger: service }
        )
        .to(
          ".service__star3",

          { y: -windowHeight - 150 + "px", scrollTrigger: service }
        )

        // .fromTo(
        //   ".service__blue",
        //   { opacity: 0.8 },
        //   { opacity: 0, scrollTrigger: service }
        // )
        .to(".service__blue-gas", { xPercent: 20, scrollTrigger: service });
      tlHome.fromTo(
        ".menu__track",
        { width: 345 },
        { width: 460, scrollTrigger: service }
      );
    },
  });
};

export default ModuleOnScroll;
